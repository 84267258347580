import { ActivityIndicator } from '@troon/ui';
import { For, Show, Suspense } from 'solid-js';
import { gql } from '../graphql';
import type { PaymentInfoFragment } from '../graphql';
import type { JSX } from 'solid-js';

type Props = {
	fallbackContent?: JSX.Element;
	receipt?: PaymentInfoFragment;
	upsell?: JSX.Element;
};

export function PaymentInfo(props: Props) {
	return (
		<Suspense fallback={<ActivityIndicator class="text-brand">Calculating…</ActivityIndicator>}>
			<table class="w-full">
				<thead class="sr-only">
					<tr>
						<th>Item</th>
						<th class="text-end font-semibold">Amount</th>
					</tr>
				</thead>
				<tbody class="border-b border-neutral-500">
					<For each={props.receipt?.items}>
						{(item) => (
							<tr>
								<td class="py-2">{item.label}</td>
								<td class="py-2 text-end font-semibold">{item.amount.displayValue}</td>
							</tr>
						)}
					</For>
					<Show when={props.upsell}>
						{(upsell) => (
							<tr>
								<td colspan={2} class="pb-2">
									{upsell()}
								</td>
							</tr>
						)}
					</Show>
				</tbody>
				<Show when={props.receipt}>
					<tfoot>
						<tr class="text-lg">
							<td class="pt-4 font-semibold">Total due now</td>
							<td class="pt-4 text-end font-semibold">{props.receipt?.dueNow.displayValue}</td>
						</tr>
						<tr>
							<td class="pb-4">Total due at check in</td>
							<td class="pb-4 text-end font-semibold">{props.receipt?.dueLater.displayValue}</td>
						</tr>
					</tfoot>
				</Show>
			</table>
		</Suspense>
	);
}

gql(`
fragment PaymentInfo on Receipt {
  total { ...Currency }
	dueNow { ...Currency }
	dueLater { ...Currency }
  items {
    label
    amount { ...Currency }
    itemType
  }
}
`);
